import axios from "axios";
import api, { API_ML_BASE_URL } from "../services";
import api_ml from "../services";

export const getEmployerCompany = async (query) => {
  try {
    const response = await api.get(
      `/autofill/external/v1/companies-employer?q=${query}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching company data:", error);
    return [];
  }
};

export const getCompanyData = async (name, linkedin_url) => {
  try {
    const response = await api.get(
      `/autofill/external/v1/company-autofill?linkedin-url=${linkedin_url}&company-name=${name}`
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching company data:", error);
    throw error;
  }
};

export const getCompanyByName = async (name) => {
  try {
    const response = await api.get(
      `/autofill/external/v1/company-autofill?company-name=${name}`
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching company data:", error);
    throw error;
  }
};

export const getCountries = async (name) => {
  try {
    const response = await api.get(
      `/autofill/external/v1/countries?q=${name}`
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching company data:", error);
    throw error;
  }
};

export const createProfile = async (userProfile, token) => {
  try {
    const response = await api.post(
      "/employer/external/v1/company",
      userProfile,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    console.log("Error fetching create Profile:", error);
    throw error;
  }
};

export const getEmployerProfile = async (token) => {
  try {
    const res = await api.get("/employer/external/v1/profile", {
      headers: { Authorization: `Bearer ${token}` },
    });

    return res;
  } catch (error) {
    console.error("Error fetching basic profile data:", error);
    throw error;
  }
};

export const remainingCredit = async (token) => {
  try {
    const res = await api.get(
      "/employer/external/v1/job-post/remaining-creds",
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return res;
  } catch (error) {
    throw error;
  }
};

export const getCvMaskingCredits = async (token) => {
  try {
    const res = await api.get(
      "/employer/external/v1/cv-masking/credits/total",
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const getCvMaskingProducts = async (token) => {
  try {
    const res = await api.get(
      "/employer/external/v1/cv-masking/products",
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return res?.data;
  } catch (error) {
    throw error;
  }
};


export const getpool = async (token) => {
  try{
    const res = await api.get(`employer/external/v1/pool/purchase-history` , {
      headers: { Authorization: `Bearer ${token}` },
    });
    return res.data;
  }catch (error){
    throw error;
  }
}

//PATCH Basic profile
export const patchBasicProfile = (userInfoUpdated, token) => {
  return api.put(`/employer/external/v1/profile`, userInfoUpdated, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

/**
 * Deletes an account by making a DELETE request to a specific API endpoint.
 * @param {string} token - The authentication token used to authorize the request.
 * @param {object} payload - The payload data to be sent in the request body.
 * @returns {Promise} - A promise that resolves with the response data from the API if the request is successful.
 * @throws {Error} - If an error occurs during the request.
 */
export const deleteAccountpermennatly = async (token, payload) => {
  try {
    const response = await api.delete("/employer/external/v1/profile", {
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deactivateAccountRequest = async (token) => {
  try {
    const response = await api.get("/employer/external/v1/profile", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// POST JOB APIS

export const getJobTitlesOfIndustry = async (query, industry, token) => {
  // /autofill/external/v1/industry/job-titles?q=mechani&industry=energy
  try {
    const response = await api.get(
      `/autofill/external/v1/industry/job-titles?q=${query}&industry=${industry}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching job titles:", error);
    return [];
  }
};

export const generateRolesResponsibilities = async (title, sector) => {
  try {
    const response = await api.get(
      `/autofill/external/v1/roles-responsiblities?title=${title}&sector=${sector}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching roles and responsibilities:", error);
    return [];
  }
};

// AUTOFILL APIS
export const getSkillSuggestionEmployer = async (query, token) => {
  try {
    const response = await api.get(
      `/autofill/external/v1/skills?name=${query}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching skills:", error);
    return [];
  }
};

export const getCompaniesEmployer = (token) => {
  // Define the API endpoint with the "q" query parameter
  const endpoint = `/employer/external/v1/company`;

  return api.get(endpoint, {
    headers: {
      Authorization: `Bearer ${token}`, // Include the bearer token
    },
  });
};

export const createNewProfile = async (userProfile, token) => {
  try {
    const response = await api.post(
      "/employer/external/v1/recruiter/company",
      userProfile,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    console.log("Error fetching create Profile:", error);
    throw error;
  }
};

export const updateRecriterCompany = async (company_id, values, token) => {
  try {
    const response = await api.patch(
      `/employer/external/v1/recruiter/company/${company_id}`,
      values,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    console.log("Error fetching create Profile:", error);
    throw error;
  }
};

export const getrecruiterById = async (token, jobId) => {
  try {
    const response = await api.get(
      `/employer/external/v1/recruiter/company/${jobId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching recruiter company by ID:", error);
    throw error;
  }
};

export const employerCompany = async (token) => {
  try {
    const response = await api.get("employer/external/v1/company", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching employer Company:", error);
    throw error;
  }
};

export const getrecruiterCompany = async (token) => {
  try {
    const response = await api.get("employer/external/v1/recruiter/companies", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching employer Company:", error);
    throw error;
  }
};

export const patchemployerCompany = (company_id, userData, token) => {
  return api.patch(`/employer/external/v1/company/${company_id}`, userData, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const deleteRecruiterComapnybyId = (token, jobId) => {
  try {
    const response = api.delete(
      `/employer/external/v1/recruiter/company/${jobId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching recruiter company by ID:", error);
    throw error;
  }
};

export const getAllCompany = async (token) => {
  try {
    const response = await api.get(`employer/external/v1/recruiter/companies`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching Comapny:", error);
    throw error;
  }
};

export const getAddress = async(token) => {
  try {
    const response = await api.get(`employer/external/v1/address`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error("Error getting  Address:", error);
    throw error;
  }
};

export const postJob = async ( headers,postJobData,) => {
  try {
    const response = await api.post(
      `/employer/external/v1/job-post`,
      postJobData,
      { headers }
    );
    return response;
  } catch (error) {
    throw error;
  }
};
export const postTrialJob = async (headers, postJobData) => {
  try {
    const response = await api.post(
      `/employer/external/v1/job-post/trial`,
      postJobData,
      { headers }
    );
    return response;
  } catch (error) {
    throw error;
  }
};


// export const VerificationRequest = async (headers, payload) => {
//   try {
//     const response = await api.post(`/applicant/external/v1/scaling/scaling-questions`,payload,
//       { headers }
//     );
//     return response;
//   } catch (error) {
//     throw error;
//   }
// };


export const updatePostJob = async (headers, postJobData,jobPost_id) => {
  try {
    const response = await api.put(
      `/employer/external/v1/job-post/${jobPost_id}`,
      postJobData,
      { headers }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getCurrencyList = async () => {
  try {
    const response = await api.get(`/autofill/external/v1/currencies`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const AddBillingAddress = async (userDetails, token) => {
  try {
    const response = await api.post(
      "employer/external/v1/address",
      userDetails,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};


export const UpdateBillingAddress = async (userDetails, token) => {
  try {
    const response = await api.patch(
      "employer/external/v1/address",
      userDetails,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getCountry = async (query) => {
  try {
    const response = await api.get(`autofill/external/v1/countries?q=${query}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};


export const getState = async (state_id) =>{
  try{
   const response = await api.get(`autofill/external/v1/states/${state_id}`)
   return response.data
  } catch(error) {
    console.log(error)
  }
}

export const getAvuaPoolCandidatesCount = async ( queryFilters, token) => {
  try {
    const response = await api.post(
      `/employer/external/v1/pool/count`,
      queryFilters,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAvuaPoolCandidates = async ( queryFilters, token) => {
  try {
    const response = await api.post(
      `/employer/external/v1/pool/search`,
      queryFilters,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getTotalPoolCredits = async (token) => {
  try {
    const response = await api.get(
      `/employer/external/v1/pool/total-credits`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getLeastCreditDetails = async (token) => {
  try {
    const response = await api.get(
      `/employer/external/v1/pool/least-credits`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getEmployerVisitedDates = async (token, limit, offset) => {
  try {
    const response = await api.get(
      `/employer/external/v1/pool/applicant/visited-dates?limit=${limit}&offset=${offset}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getViewedCandidates = async (token,date, limit, offset) => {
  try {
    const response = await api.get(
      `/employer/external/v1/pool/viewed-candidates?visited_on=${date}&limit=${limit}&offset=${offset}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPoolSearchHistory = async (token) => {
  try {
    const response = await api.get(
      `/employer/external/v1/pool/search-history`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const postCvMaskingRequest = async ( queryFilters, token) => {
  try {
    const response = await api.post("/employer/external/v1/cv-masking/mask", 
    queryFilters,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
    );
    return response;
  } catch (error) {
    throw error;
  }
};
export const getCvSimilarRequest = async (
  queryParamsPath,
  token,
  country = "None"
) => {
  try {
    console.log("Calling the CV parser API for similar CV");
    // Define the base params object without the country_code
    const params = {
      "cv-path": queryParamsPath,
    };
    // Add country_code only if it's not 'None' or an empty string
    if (country && country !== "None") {
      params["country_code"] = country;
    }
    const response = await api.get(
      "/employer/external/v1/similar_cv/cv-parser",
      {
        headers: { Authorization: `Bearer ${token}` },
        params: params,
      }
    );
    console.log(
      "This is the response I got for CV-parser similar CV",
      response
    );
    return response;
  } catch (error) {
    throw error;
  }
};


export const getCvSimilarCredits = async (token) => {
  try {
    const res = await api.get(
      "/employer/external/v1/similar_cv/credits/total",
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return res?.data;
  } catch (error) {
    throw error;
  }
};
export const getCvSimilarProducts = async (token, countryCode = 'IN') => {
  try {
    if(!countryCode) return 
    const adjustedCountryCode = countryCode == "IN" ? "IN" : "US";
    const res = await api.get("/payments/external/v1/products", {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        country_code: adjustedCountryCode,
        product_type: "similar_cv"
      }
    });
    console.log('This is the product data and pricing ', res?.data);

    return res?.data;
  } catch (error) {
    throw error;
  }
};
export const getSimilarCvHistoryList = async (
  token,
  limit,
  offset
) => {
  try {
    const response = await api.get("/employer/external/v1/similar_cv/cv-list", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        limit: limit,
        offset: offset,
      },
    });
    console.log('this is CV Similar History',response?.data)
    return response?.data;
  } catch (error) {
    throw error;
  }
};
export const getViewedCandidatesIdealMatch = async (token, limit, offset, similarCvId) => {
  try {
    const response = await api.get(
      `/employer/external/v1/pool/viewed-candidates?limit=${limit}&offset=${offset}&employer_ref_cvs_id=${similarCvId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
import React, { lazy, Suspense } from "react";
import PageLoader from "../components/elements/micros/PageLoader.jsx";
import { elements } from "chart.js";

const Unsubscribe = lazy(() => import("../pages/UnSubscribePage.jsx"));
const Applicant = lazy(() => import("../pages/Landing/Applicant.jsx"));
const AboutCompanyPage = lazy(() =>
  import("../pages/Landing/AboutCompanyPage.jsx")
);
// const AboutUs = lazy(() => import("../pages/Landing/AboutUsPage.jsx"));
const Employer = lazy(() => import("../pages/Landing/Employer.jsx"));
// const Recruiter = lazy(() => import("../pages/Landing/RecruiterPage.jsx"));
const Blogs = lazy(() => import("../pages/Landing/blogs/BlogsPage.jsx"));
const BlogDetails = lazy(() => import("../pages/Landing/blogs/BlogDetails.jsx"));
const UploadResume = lazy(() =>
  import("../pages/Applicant/UploadResumePage.jsx")
);
const SignupPage = lazy(() => import("../pages/Applicant/SignupPage.jsx"));
const LoginPage = lazy(() => import("../pages/Applicant/LoginPage.jsx"));
const EmployerVerifyEmail = lazy(() =>
  import("../pages/EmployerVerifyEmail.jsx")
);
const ForgotPasswordPage = lazy(() =>
  import("../pages/Applicant/ForgotPasswordPage.jsx")
);
const VideoResume = lazy(() =>
  import("../pages/Applicant/VideoResume/VideoResume.jsx")
);
const StartRecordingScreen = lazy(() =>
  import("../pages/Applicant/StartRecordingScreen.jsx")
);
const PreviewScreen = lazy(() =>
  import("../pages/Applicant/PreviewScreen.jsx")
);
const SettingsDashboard2 = lazy(()=>
  import("../pages/Employer/Employer-settings/SettingsDashboard2.jsx")
)
const PasswordChangedPage = lazy(() =>
  import("../pages/Applicant/PasswordChangedPage.jsx")
);
const TermandConditionPage = lazy(() =>
  import("../pages/Landing/TermandConditionPage.jsx")
);
const VerifyingEmailPage = lazy(() =>
  import("../pages/VerifyingEmailPage.jsx")
);
const ProcessPage = lazy(() => import("../pages/Applicant/ProcessPage.jsx"));
const CreateResume = lazy(() => import("../pages/Applicant/CreateResume.jsx"));
const CreatePrefrences = lazy(() =>
  import("../pages/Applicant/CreatePrefrences.jsx")
);
const VerficationFirstStepPage = lazy(() =>
  import("../pages/Applicant/VerficationFirstStepPage.jsx")
);
const DashboardPage = lazy(() =>
  import("../pages/Applicant/DashboardPage.jsx")
);
const DashBoardPage2 = lazy(() =>
  import("../pages/Applicant/DashBoardPage2.jsx")
);

const StartVerificationPage = lazy(() =>
  import("../pages/Applicant/StartVerificationPage.jsx")
);
// const PrivacyPolicy = lazy(() => import("../pages/Landing/PrivacyPolicy.jsx"));
const PrivacyPolicyPage = lazy(() => import("../pages/Landing/PrivacyPolicyPage.jsx"));
const LandingPage = lazy(() => import("../pages/Landing/LandingPage.jsx"));
const HomePage = lazy(() => import("../pages/Landing/HomePage.jsx"));
const AIEthicsPolicies = lazy(() =>
  import("../pages/Landing/AIEthicsPolicies.jsx")
);
const EmployerRoutes = lazy(() => import("./EmployerRoutes.jsx"));
const Pricing = lazy(() => import("../pages/Pricing.jsx"));
const PurchaseCredits = lazy(() => import("../pages/Employer/PurchaseCredits"))
const RecommendedCandidates = lazy(() =>
  import("../pages/Employer/RecommendedCandidates/RecommendedCandidates.jsx")
);
// /post-job/get-started
// /post-job/job-title
// /post-job/job-details
// /post-job/preffered-candidate-details
// /post-job/preview-job-details
const EmployerDetails = lazy(() =>
  import("../pages/Employer/AuthCompany/EmployerDetails.jsx")
);
const GetStarted = lazy(() =>
  import("../pages/Employer/PostJob/GetStarted.jsx")
);
const JobTitle = lazy(() => import("../pages/Employer/PostJob/JobTitle.jsx"));
const JobDetails = lazy(() =>
  import("../pages/Employer/PostJob/JobDetails.jsx")
);
const PreferredCandidateDetails = lazy(() =>
  import("../pages/Employer/PostJob/PreferredCandidateDetails.jsx")
);
const PreviewJobDetails = lazy(() =>
  import("../pages/Employer/PostJob/PreviewJobDetails.jsx")
);
const AlgorithmParameters = lazy(() =>
  import("../pages/Employer/PostJob/AlgorithmParameters.jsx")
);

const RecommendedJob = lazy(() =>
  import("../pages/Applicant/RecommendedJob.jsx")
);

const PrePricing = lazy(() =>
  import("../pages/Employer/Pricing/PrePricing.jsx")
);

const UdemyPage = lazy(() => import("../pages/Applicant/UdemyPage.jsx"));

const CreateComanyPage = lazy(() =>
  import("../pages/Employer/AuthCompany/CreateComanyPage.jsx")
);
const ReviewPending = lazy(() => import("../pages/Employer/ReviewPending.jsx"));
const AvuaPoolPage = lazy(() => import("../pages/Landing/AvuaPoolPage.jsx"));
const AvuaPool = lazy(() => import("../pages/Employer/new-avua-pool/Index.jsx"));
const EmployerAuthentication = lazy(() => import('../pages/EmployerAuthentication.jsx'))
const Authentication  = lazy(() => import('../pages/Authentication.jsx'))
const EmployerRegister =lazy(() => import('../pages/Employer/AuthCompany/EmployerRegister.jsx'))
const EmployerLogin = lazy(() =>import('../pages/Employer/AuthCompany/EmployerLogin.jsx'))
const ApplicantLogin = lazy(() => import("../pages/Applicant/applicantAuthenticatin/ApplicantLogin.jsx")) 
const ApplicantRegister = lazy(() => import("../pages/Applicant/applicantAuthenticatin/ApplicantRegister.jsx"))
const SearchHistory = lazy(() => import("../pages/Employer/pool-search-history/Index.jsx"));
const MaskingCv = lazy(() => import("../pages/Employer/cv-masking/Index.jsx"));
const IdealMatch = lazy(() => import("../pages/Employer/cv-similar/SimilarCv.jsx"));
const  ApplicantPrefrences = lazy(() => import('../pages/Applicant/applicantAuthenticatin/ApplicantPrefrences.jsx'));
const SkillTest = lazy(() => import('../pages/Applicant/SkillTest.jsx'))
const IdealMatchSearch = lazy(() => import("../pages/Employer/avua-pool/idealMatch.jsx"));
const Error = lazy(() => import("../pages/Error.jsx"));
export const nav = [
  //*** Public Routes ***
  // Landing Pages
  {
    path: "/",
    name: "applicant",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        {/* <LandingPage /> */}
        <HomePage />
      </Suspense>
    ),
    isMenu: true,
    isPrivate: false,
  },
  {
    path: "/applicant",
    name: "applicant",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <Applicant />
      </Suspense>
    ),
    isMenu: true,
    isPrivate: false,
  },
  {
    path: "/about-us",
    name: "About",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        {/* <AboutUs /> */}
        <AboutCompanyPage />
      </Suspense>
    ),
    isMenu: true,
    isPrivate: false,
  },
  {
    path: "/employer",
    name: "About",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <Employer />
      </Suspense>
    ),
    isMenu: true,
    isPrivate: false,
  },
  // {
  //   path: "/recruiter",
  //   name: "Recruiter",
  //   element: (
  //     <Suspense fallback={<PageLoader avuaLoader={true} />}>
  //       <Recruiter />
  //     </Suspense>
  //   ),
  //   isMenu: true,
  //   isPrivate: false,
  // },

  {
    path: "/avua-pool-page",
    name: "AvuaPool",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <AvuaPoolPage />
      </Suspense>
    ),
    isMenu: true,
    isPrivate: false,
  },

  {
    path: "/blogs",
    name: "Blogs",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <Blogs />
      </Suspense>
    ),
    isMenu: true,
    isPrivate: false,
  },
  {
    path: "/blogs/:id",
    name: "BlogDetails",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <BlogDetails />
      </Suspense>
    ),
    isMenu: true,
    isPrivate: false,
  },

  {
    path: "/employer-authentication-page",
    name: "EmployerAuthentication",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <EmployerAuthentication  />
      </Suspense>
    ),
    isMenu: false,
    isPrivate: false,
  },
  {
    path: "/applicant-authentication-page",
    name: "Authentication",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <Authentication />
      </Suspense>
    ),
    isMenu: false,
    isPrivate: false,
  },

  // {
  //   path: "/login",
  //   name: "Login",
  //   element: (
  //     <Suspense fallback={<PageLoader avuaLoader={true} />}>
  //       <LoginPage />
  //     </Suspense>
  //   ),
  //   isMenu: false,
  //   isPrivate: false,
  // },

  // {
  //   path: "/signup",
  //   name: "SignUp",
  //   element: (
  //     <Suspense fallback={<PageLoader avuaLoader={true} />}>
  //       <SignupPage />
  //     </Suspense>
  //   ),
  //   isMenu: false,
  //   isPrivate: false,
  // },

  {
    path: "/login",
    name: "Login",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <ApplicantLogin />
      </Suspense>
    ),
    isMenu: false,
    isPrivate: false,
  },
  {
    path: "/signup",
    name: "SignUp",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <ApplicantRegister />
      </Suspense>
    ),
    isMenu: false,
    isPrivate: false,
  },

  {
    path: "/employer-sign-up",
    name: "EmployerRegister",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <EmployerRegister />
      </Suspense>
    ),
    isMenu: false,
    isPrivate: false,
  },

  {
    path: "/employer-login",
    name: "EmployerLogin",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <EmployerLogin />
      </Suspense>
    ),
    isMenu: false,
    isPrivate: false,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <ForgotPasswordPage />
      </Suspense>
    ),
    isMenu: false,
    isPrivate: false,
  },

  {
    path: "/skill-test",
    name: "SkillTest",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <SkillTest />
      </Suspense>
    ),
    isMenu: false,
    isPrivate: true,
  },
  
  {
    path: "/password-changed",
    name: "PasswordChanged",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <PasswordChangedPage />
      </Suspense>
    ),
    isMenu: false,
    isPrivate: false,
  },
  {
    path: "/employer-details",
    name: "EmployerDetails",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <EmployerDetails />
      </Suspense>
    ),
    isMenu: false,
    isPrivate: false,
  },
  {
    path: "/terms-conditions",
    name: "TermsandConditions",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <TermandConditionPage />
      </Suspense>
    ),
    isMenu: true,
    isPrivate: false,
  },

  {
    path: "/ai-ethics",
    name: "AIEthicsPolicies",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <AIEthicsPolicies />
      </Suspense>
    ),
    isMenu: true,
    isPrivate: false,
  },
  {
    path: "/users/external/v1/email-verify",
    name: "VerifyingEmailPage",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <VerifyingEmailPage />
      </Suspense>
    ),
    isMenu: false,
    isPrivate: false,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        {/* <PrivacyPolicy /> */}
        <PrivacyPolicyPage />
      </Suspense>
    ),
    isMenu: true,
    isPrivate: false,
  },
  //upon verifyingEmail, set redux isAuthenticated=true -> get access to protected routes
  //*** Protected Routes : applicant ***
  {
    path: "/process-page",
    name: "Private",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <ProcessPage />
      </Suspense>
    ),
    isMenu: false,
    isPrivate: true,
  },
  {
    path: "/first-step",
    name: "FirstStep",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <VerficationFirstStepPage />
      </Suspense>
    ),
    isMenu: false,
    isPrivate: true,
  },

  {
    path: "/udemy-course",
    name: "UdemyPage",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <UdemyPage />
      </Suspense>
    ),
    isMenu: false,
    isPrivate: true,
  },
  {
    path: "/start-verification",
    name: "startVerificaton",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <StartVerificationPage />
      </Suspense>
    ),
    isMenu: false,
    isPrivate: true,
  },
  {
    path: "/upload-resume",
    name: "uploadResume",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <UploadResume />
      </Suspense>
    ),
    isMenu: false,
    isPrivate: true,
  },
  {
    path: "/create-resume",
    name: "createResume",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <CreateResume />
      </Suspense>
    ),
    isMenu: false,
    isPrivate: true,
  },

  // {
  //   path: "/create-Prefrences",
  //   name: "createPrefrences",
  //   element: (
  //     <Suspense fallback={<PageLoader avuaLoader={true} />}>
  //       <CreatePrefrences />
  //     </Suspense>
  //   ),
  //   isMenu: false,
  //   isPrivate: true,
  // },

  {
    path: "/on-boarding",
    name: "applicantPrefrences",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <ApplicantPrefrences />
      </Suspense>
    ),
    isMenu: false,
    isPrivate: false,
  },

  {
    path: "/video-resume",
    name: "VideoResume",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <VideoResume />
      </Suspense>
    ),
    isMenu: false,
    isPrivate: true,
  },
  {
    path: "/startrecordingscreen",
    name: "StartRecordingScreen",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <StartRecordingScreen />
      </Suspense>
    ),
    isMenu: false,
    isPrivate: true,
  },
  {
    path: "/prvscrn",
    name: "preview",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <PreviewScreen />
      </Suspense>
    ),
    isMenu: false,
    isPrivate: true,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <DashBoardPage2 />
      </Suspense>
    ),
    isMenu: false,
    isPrivate: false,
  },

  {
    path: "/users/email-unsubscribe",
    name: "Unsubscribe",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <Unsubscribe />
      </Suspense>
    ),
    isMenu: false,
    isPrivate: false,
  },
  {
    path: "/avua-pool",
    name: "AvuaPool",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <AvuaPool />
      </Suspense>
    ),
    isMenu: false,
    isPrivate: true,
  },
  {
    path: "/search-history",
    name: "SearchHistory",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <SearchHistory />
      </Suspense>
    ),
    isMenu: false,
    isPrivate: true,
  },
  {
    path: "/cv-masking",
    name: "MaskingCv",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <MaskingCv />
      </Suspense>
    ),
    isMenu: false,
    isPrivate: true,
  },
  {
    path: "/employer/external/v1/email-verify",
    name: "EmployerVerifyEmail",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <EmployerVerifyEmail />
      </Suspense>
    ),
    isMenu: false,
    isPrivate: false,
  },
  {
    path: "/employer/settings",
    name: "EmployerSettings",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <SettingsDashboard2 />
      </Suspense>
    )
  },
  {
    path: "/employer/*",
    name: "EmployerSection",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <EmployerRoutes />
      </Suspense>
    ),
  },
  {
    path: "employer/recommended-candidates",
    name: "RecommendedCandidates",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <RecommendedCandidates />
      </Suspense>
    ),
    isMenu: false,
    isPrivate: true,
  },
  {
    path: "/pricing",
    name: "Pricing",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <Pricing />
      </Suspense>
    ),
  },
  {
    path: "/purchase-credits",
    name: "PurchaseCredits",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <PurchaseCredits />
      </Suspense>
    ),
  },

  {
    path: "/pre-pricing/",
    name: "PrePricing",
    element: (
      <Suspense fallback={<PageLoader />}>
        <PrePricing />
      </Suspense>
    ),
    isMenu: false,
    isPrivate: false,
  },
  {
    path: "/employer/post-job/get-started",
    name: "EmployerGetStarted",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <GetStarted />
      </Suspense>
    ),
    isMenu: false,
    isPrivate: true,
  },
  {
    path: "/employer/post-job/job-title",
    name: "EmployerJobTitle",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <JobTitle />
      </Suspense>
    ),
    isMenu: false,
    isPrivate: true,
  },
  {
    path: "/employer/post-job/job-details",
    name: "EmployerJobDetails",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <JobDetails />
      </Suspense>
    ),
    isMenu: false,
    isPrivate: false,
  },
  {
    path: "/employer/post-job/preffered-candidate-details",
    name: "EmployerCandidateDetails",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <PreferredCandidateDetails />
      </Suspense>
    ),
    isMenu: false,
    isPrivate: false,
  },
  {
    path: "/employer/post-job/algorithm-parameters",
    name: "EmployerAlgorithm",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <AlgorithmParameters />
      </Suspense>
    ),
    isMenu: false,
    isPrivate: false,
  },
  {
    path: "/employer/post-job/preview-job-details",
    name: "EmployerPreviewJob",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <PreviewJobDetails />
      </Suspense>
    ),
    isMenu: false,
    isPrivate: false,
  },

  {
    path: "/create-company",
    name: "CreateComanyPage",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <CreateComanyPage />
      </Suspense>
    ),
    isMenu: false,
    isPrivate: false,
  },

  {
    path: "/recommended-jobs",
    name: "RecommendedJob ",
    element: (
      <Suspense fallback={<PageLoader />}>
        <RecommendedJob />
      </Suspense>
    ),
    isMenu: false,
    isPrivate: true,
  },
  {
    path: "/up-for-review",
    name: "ReviewPending ",
    element: (
      <Suspense fallback={<PageLoader />}>
        <ReviewPending />
      </Suspense>
    ),
    isMenu: false,
    isPrivate: false,
  },
  {
    path: "/idealMatch",
    name: "idealMatch",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <IdealMatch />
      </Suspense>
    ),
    isMenu: false,
    isPrivate: true,
  },
  {
    path: "/idealMatch/search",
    name: "IdealMatchSearch",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <IdealMatchSearch />
      </Suspense>
    ),
    isMenu: false,
    isPrivate: true,
  },
  {
    path: "/error",
    name: "Error",
    element: (
      <Suspense fallback={<PageLoader avuaLoader={true} />}>
        <Error />
      </Suspense>
    ),
    isMenu: false,
    isPrivate: true,
  },
  
];
